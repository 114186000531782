<template>
  <div class="login-wrapper" :style="{height: height + 'px'}">
    <div class="login-box">
      <div class="logo">
        <img src="">
        <p class="text">满电行新能源充电管理系统</p>
      </div>
      <div class="login-content">
        <div class="login-form">
          <Form ref="loginForm" :model="formData" :rules="formRules" @keydown.native.enter.prevent="login">
            <FormItem prop="userName" label="用户名:">
              <Input type="password" class="hide-input"/>
              <Input v-model="formData.userName" autocomplete="off">
                <Icon slot="prepend" type="md-person"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="password" label="密码:">
              <Input v-model="formData.password" type="password" autocomplete="off">
                <Icon slot="prepend" type="ios-lock"></Icon>
              </Input>
            </FormItem>
          </Form>
          <Button type="info" class="login-btn" @click="toLogin" :disabled="loading" :loading="loading">登录</Button>
        </div>
      </div>
      <div class="footer">
        <p>Copyright © 2011-2023 <a :href="globalConfig.companyUrl" target="_blank">{{ globalConfig.companyUrl }}</a> 版权所有:
          {{ globalConfig.company }} 公网备案
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ globalConfig.icpNum }}</a>
        </p>
        <p class="download">APP下载：
          <a href="http://www.mdxxny.com/ApKFile/mdx_yyb.apk">安卓下载</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { setStorage } from '@/libs/tools'
import { loginNew, getUserInfo } from '@/libs/api'
import dayjs from 'dayjs'
import globalConfig from '@/config'
export default {
  name: "login",
  data () {
    return {
      globalConfig,
      height: 0,
      formData: {
        userName: '',
        password: ''
      },
      formRules: {
        userName: [{required: true, message: '请输入用户名'}],
        password: [{required: true, message: '请输入密码'}]
      },
      loading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.height =  window.innerHeight
    })
  },
  methods: {
    login(){
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.toLogin()
        }
      })
    },
    toLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('Login', this.formData).then((res) => {
            getUserInfo().then(res => {
              this.loading = false
              if (res.data.code === 0) {
                let user = res.data.data
                if (user.userPower.length) {
                  let router = this.redirect || '/home'
                  if (user.userType === -1) {
                    router = '/big-screen'
                  }
                  this.$router.push({ path: router }).catch(()=>{})
                } else {
                  this.$Message.error('暂无权限，请联系管理员!')
                }
              }
            })
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    resetForm () {
      this.$refs.loginForm.resetFields()
      this.formData = {
        userName: '',
        password: ''
      }
    },
    toDownload () {
      let src = 'http://www.hiwaypower.com/ApKFile/mdx_yyb.apk'
      window.onload(src)
    }
  }
};
</script>

<style lang="less" scoped>
  @import "login";
</style>
